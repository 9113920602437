import React from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "@homesusa/layout";
import { activateUser } from "core/services/user.service";
import { UserContext } from "../contexts";

export const useActivateUser = (): (() => Promise<void>) => {
  const navigate = useNavigate();
  const { addAlert } = React.useContext(AppContext);
  const { user } = React.useContext(UserContext);

  const onActivate = async (): Promise<void> => {
    return activateUser(user.id).then(() => {
      addAlert({
        message: "The user was successfully restored",
        variant: "success"
      });
      navigate("/users");
    });
  };

  return onActivate;
};
