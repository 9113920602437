import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Column } from "react-table";

import {
  GridTable,
  GridProvider,
  GridToolbar,
  FetchDataDownloading,
  GridResponse,
  useFetchData
} from "@homesusa/grid-table";
import {
  Format,
  PersistentRightModal,
  PersistentRightModalContext,
  RoleEmployee
} from "@homesusa/core";
import { AppContext, SubmitButton } from "@homesusa/layout";
import { useHasRole } from "@homesusa/auth";
import { FormContext } from "@homesusa/form";
import { getUsers, createUser } from "core/services/user.service";
import { UserDetail } from "core/interfaces";
import { UserCreateFormProvider } from "../providers";
import { UserCreateForm } from "../components";
import { EntityStatus, EntityStatusLabel } from "core/enums";

function SaveButton({
  getUsers
}: {
  getUsers: (props: { pageSize: number; pageIndex: number }) => void;
}): JSX.Element {
  const { addAlert } = React.useContext(AppContext);
  const { formState } = React.useContext(FormContext);
  const { setShowRightModal } = React.useContext(PersistentRightModalContext);

  return (
    <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
      <SubmitButton
        onClick={async (): Promise<void> =>
          createUser(formState).then((): void => {
            addAlert({
              message: "The request was successfully created",
              variant: "success"
            });
            getUsers({ pageSize: 50, pageIndex: 0 });
            setShowRightModal(false);
          })
        }
      >
        Save
      </SubmitButton>
    </Box>
  );
}

function UserGrid(): JSX.Element {
  const { isMlsAdministrator, hasEmployeeRole } = useHasRole();
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  const defaultSortBy = "+firstName,+lastName";
  const columns: Array<Column<UserDetail>> = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: (data: UserDetail): string => data.firstName
      },
      {
        Header: "Last Name",
        accessor: (data: UserDetail): string => data.lastName
      },
      {
        Header: "Username",
        accessor: (data: UserDetail): string => data.email,
        id: "email"
      },
      {
        Header: "Email Confirmed",
        accessor: (data: UserDetail): string =>
          data.emailConfirmed ? "Yes" : "No"
      },
      {
        Header: "Business Phone",
        accessor: (data: UserDetail): string =>
          Format.PhoneNumber(data.businessPhone) ?? "",
        disableSortBy: true
      },
      {
        Header: "Mobile Phone",
        accessor: (data: UserDetail): string =>
          Format.PhoneNumber(data.mobilePhone) ?? "",
        disableSortBy: true
      },
      {
        Header: "Created",
        accessor: (data: UserDetail): string =>
          Format.DateTime(data.sysCreatedOn),
        id: "sysCreatedOn"
      },
      {
        Header: "Last Activity",
        accessor: (data: UserDetail): string => Format.DateTime(data.lastLogin),
        id: "lastLogin"
      },
      {
        Header: "Role",
        accessor: (data: UserDetail): string => data.roles.toString(),
        id: "roleName"
      }
    ],
    []
  );
  const [entityStatus, setEntityStatus] = React.useState<string[]>(
    Object.values(EntityStatus).slice(0, 1)
  );

  const getUser = ({ row }: { row: UserDetail }) => {
    navigate(`/users/${row.id}`);
  };

  const getGridData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      sortBy,
      globalFilter,
      isForDownloading
    }: FetchDataDownloading): Promise<GridResponse<UserDetail>> => {
      return getUsers({
        top: pageSize,
        skip: pageIndex,
        searchBy: globalFilter,
        sortBy: sortBy ?? defaultSortBy,
        isForDownloading,
        status: entityStatus
      });
    },
    [entityStatus]
  );
  const { data, fetchData, fetchDataForDownloading } =
    useFetchData<UserDetail>(getGridData);

  return (
    <Fragment>
      <GridProvider
        options={{ columns, data: data.data }}
        totalRows={data.total}
        fetchData={fetchData}
      >
        <GridToolbar title="Users">
          {(isMlsAdministrator ||
            hasEmployeeRole([RoleEmployee.CompanyAdmin])) && (
            <GridToolbar.AddButton
              onClick={(): void => setShowAddModal(true)}
            />
          )}
          <GridToolbar.RefreshButton />
          {data.total > 0 && (
            <GridToolbar.ExportButtons
              onClick={fetchDataForDownloading}
              fileName="users"
            />
          )}
          {isMlsAdministrator && (
            <GridToolbar.SelectFilter
              defaultValue={EntityStatus.Active}
              onChange={(value): void => setEntityStatus([value])}
              options={Object.values(EntityStatus).reduce(
                (prev, next) => ({
                  ...prev,
                  [next.toString()]: EntityStatusLabel.get(next)
                }),
                {}
              )}
              label="Status"
            />
          )}
          <GridToolbar.SearchFilter />
        </GridToolbar>
        <GridTable onRowClick={getUser} />
        <PersistentRightModal
          title="New User"
          open={showAddModal}
          onChange={(value): void => setShowAddModal(value)}
        >
          <UserCreateFormProvider>
            <UserCreateForm />
            <SaveButton getUsers={fetchData} />
          </UserCreateFormProvider>
        </PersistentRightModal>
      </GridProvider>
    </Fragment>
  );
}

export default UserGrid;
