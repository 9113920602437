import React, { Fragment } from "react";
import { Save as SaveIcon, SettingsBackupRestore } from "@mui/icons-material";

import { FormToolbar as HusaFormToolbar } from "@homesusa/form";
import { useAuth } from "@homesusa/auth";
import { SubmitButton } from "@homesusa/layout";
import { UserDetail } from "core/interfaces";
import { DeleteIconButton, InfoIconButton } from "core/components";
import { UserContext } from "../contexts";
import { useDeleteUser, useUpdateUser, useActivateUser } from "../hooks";

function UserToolbar(): JSX.Element {
  const { user: currentLoggedUser } = useAuth();
  const { user } = React.useContext(UserContext);
  const onSave = useUpdateUser();
  const onDelete = useDeleteUser();
  const onActivate = useActivateUser();
  const getUserName = (user: UserDetail): string => {
    if (user) {
      return `User - ${user.firstName} ${user.lastName} (${user.email})`;
    } else return "";
  };

  return (
    <Fragment>
      <HusaFormToolbar>
        <HusaFormToolbar.Left title={getUserName(user)} />
        <HusaFormToolbar.Right>
          {!user.isDeleted && (
            <SubmitButton
              onClick={onSave}
              buttonProps={{
                variant: "contained",
                startIcon: <SaveIcon />,
                color: "primary"
              }}
            >
              Save
            </SubmitButton>
          )}
          {currentLoggedUser?.isSuperAdmin &&
            (user.isDeleted ? (
              <SubmitButton
                onClick={onActivate}
                buttonProps={{
                  variant: "contained",
                  startIcon: <SettingsBackupRestore />,
                  color: "primary"
                }}
              >
                Restore
              </SubmitButton>
            ) : (
              <DeleteIconButton
                text="Delete User"
                onClick={onDelete}
                modalBody={<p>Are you sure you want to delete this user?</p>}
              />
            ))}
          <InfoIconButton information={user} />
        </HusaFormToolbar.Right>
      </HusaFormToolbar>
    </Fragment>
  );
}

export default UserToolbar;
