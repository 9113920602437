import React from "react";
import { LinearProgress } from "@mui/material";

import { useAuth } from "@homesusa/auth";
import { UserProvider } from "../providers";
import { UserPanel } from "../components";

function UserProfile(): JSX.Element {
  const { user } = useAuth();
  if (!user) {
    return <LinearProgress />;
  }
  return (
    <UserProvider userId={user.id}>
      <UserPanel />
    </UserProvider>
  );
}

export default UserProfile;
