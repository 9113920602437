import React from "react";
import { UserDetail } from "core/interfaces";
import { getUserDetail } from "core/services/user.service";
import { UserContext } from "../contexts";

export function UserProvider({
  userId,
  children
}: {
  children: React.ReactNode;
  userId: string;
}): JSX.Element {
  const [user, setUser] = React.useState<UserDetail>();

  const fetchUserData = React.useCallback(() => {
    getUserDetail(userId, true).then((data: UserDetail) => {
      setUser(data);
    });
  }, [userId]);

  React.useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  if (!user) {
    return <p data-testid="loading">Loading...</p>;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        getUserList: fetchUserData
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
